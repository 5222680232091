import styles from './landingImage.module.css';
import logo_white from '../../../../common/assets/logo_white.png';
import { Link } from 'react-router-dom';


export const LandingImage = () => {
  return (
    <div className={styles.landingImageContainer}>
      <div className={styles.landingImage}>
        <div className={styles.logoContainer}>
          <div className={styles.logoContent}>
            <img className={styles.logo} src={logo_white} alt='' />
            <div className={styles.logoDescription}>
              Wir vermitteln Karrieren <br />
              Keine Zeitarbeit<br />
              <span className={styles.phoneNumber}>0541 506 760 1</span>
            </div>
          </div>
        </div>
        <div className={styles.tileContainer}>
          <div className={styles.tile}>
            <Link className={styles.tileHeader} to='applyEmployee'>Sie suchen einen Job?{'  >'}</Link>
          </div>
          <div className={styles.tile}>
            <Link className={styles.tileHeader} to='applyCompany'>Sie suchen Personal?{'  >'}</Link>
          </div>
          <div className={styles.tile}>
            <Link className={styles.tileHeader} to='contact'>Kontakt {'  >'}</Link>
          </div>
        </div>
      </div>
    </div>
  );
}