import { LandingImage } from './Container/LandingImage';
import { Benefits } from './Container/Benefits';
import { Communication } from './Container/Communication';
import { Conclusion } from './Container/Conclusion';


export const Home = () => {
  return (
    <>
      <LandingImage />
      <Benefits />
      <Communication />
      <Conclusion />
    </>
  );
}