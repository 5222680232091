import styles from './conclusion.module.css';
import logo_black from '../../../../common/assets/logo_black.png';

export const Conclusion = () => {
  return (
    <div className={styles.container}>
      <hr />
      <h1 className={styles.header}>Lehnen Sie sich zurück. <br/>Wir finden genau das, was Sie suchen.</h1>
      <div className={styles.image}>
        <img className={styles.logo} src={logo_black} alt=''/>
      </div>
    </div>
  );
}