export const getXsrfCookie = (name: string) => {
    if (!document.cookie) {
        return '';
    }

    const xsrfCookies = document.cookie.split(';')
        .map(c => c.trim())
        .filter(c => c.startsWith(name + '='));

    if (xsrfCookies.length === 0) {
        return '';
    }
    return decodeURIComponent(xsrfCookies[0].split('=')[1]);
}

export const getCookie = (name: string) => {
    const escape = (s: string) => { return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1'); }
    let match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
    return match ? match[1] : undefined;
}