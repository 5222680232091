import { useRef, useState } from 'react';

import styles from './openableKeyword.module.css';

interface Props {
  visibleText: string,
  invisibleText: string
}


export const OpenableKeyword = ({ visibleText, invisibleText }: Props) => {
  const [buttonText, setButtonText] = useState('+');

  const invisibleTextDiv = useRef<HTMLDivElement>(null);

  const toggleButton = () => {
    buttonText === '+' ? setButtonText('-') : setButtonText('+');
    invisibleTextDiv.current?.classList.toggle(styles.visibleText);
  }

  return (
    <div className={styles.container}>
      <button className={styles.button} onClick={toggleButton}>
        <span className={styles.buttonText}>{buttonText}</span>
      </button>
      <div>
        {visibleText}
        <div className={styles.invisibleText} ref={invisibleTextDiv}>
          {invisibleText}
        </div>
      </div>
    </div>
  );
}