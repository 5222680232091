import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import styles from './menuButton.module.css';

type Props = {
  icon: IconDefinition,
  label: string,
  to: string,
  alwaysVisible?: boolean
}

export const MenuButton = ({ icon, label, to, alwaysVisible }: Props) => {
  let buttonStyle: React.CSSProperties = alwaysVisible ? { flexDirection: 'column', alignItems: 'center' } : { alignItems: 'center' }

  return (
    <Link className={styles.container} style={buttonStyle} to={to}>
      <FontAwesomeIcon className={styles.icon} icon={icon} />
      <div className={styles.link}>{label}</div>
    </Link>
  );
}