import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import styles from './consentCookies.module.css';

import { Checkbox } from '../../components/Checkbox';
import { getCookie } from '../../../services/utils';
import { OpenableKeyword } from '../../components/OpenableKeyword';


export const ConsentCookies = () => {
  const [preferenceCookies, setPreferenceCookies] = useState<boolean>(false);
  const [statisticsCookies, setStatisticsCookies] = useState<boolean>(false);

  const consentCookiesRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const cookieSettingsString = getCookie('cookieSettings');
    if (cookieSettingsString !== undefined) {
      const cookieSettings = JSON.parse(cookieSettingsString);
      if (cookieSettings.necessary || cookieSettings.preferences || cookieSettings.necassary) {
        consentCookiesRef.current!.remove();
      }
    }
  });

  const onAccept = () => {
    document.cookie = `cookieSettings={"necessary":true, "preferences":true, "statistics":true};max-age=31536000`;
    consentCookiesRef.current!.remove();
  }
  const onPartlyAccept = () => {
    document.cookie = `cookieSettings={"necessary":true, "preferences":${preferenceCookies}, "statistics":${statisticsCookies}};max-age=31536000`;
    consentCookiesRef.current!.remove();
  }
  const onRefuse = () => {
    document.cookie = 'cookieSettings={"necessary":true, "preferences":false, "statistics":false};max-age=31536000';
    consentCookiesRef.current!.remove();
  }

  return (
    <div className={styles.consentCookies} ref={consentCookiesRef}>
      <div className={styles.wrapper}>
        <h1 className={styles.header}>Diese Webseite verwendet Cookies!</h1>
        <p className={styles.text}>
          Wir verwenden Cookies auf dieser Website.<br />
          Unten haben Sie die Möglichkeit, nur notwendige, ausgewählte oder alle Cookies zu akzeptieren.
        </p>
        <p className={styles.text}>
          Die Cookie-Einstellungen können jederzeit über den Link "Cookies" unten im Footer der Website geändert werden.
        </p>
        <OpenableKeyword visibleText='Was sind Cookies?' invisibleText='Cookies sind kleine Textdateien, die der Internetbrowser auf dem Gerät 
          speichert. Sie dienen dazu, individuelle Nutzerdaten wie zum Beispiel individuelle Einstellungen, Logindaten oder andere Aktivitäten des 
          Websitenutzers speichern zu können. Cookies sind teilweise notwendig, andere helfen, die Dienste unserer Website weiter zu verbessern.'/>
        <div className={styles.checkboxContainer}>
          <Checkbox checked readOnly>Notwendige Cookies</Checkbox>
          <Checkbox onChange={(value) => setPreferenceCookies(value.target.checked)}>Präferenz-Cookies</Checkbox>
          <Checkbox onChange={(value) => setStatisticsCookies(value.target.checked)}>Statistik-Cookies</Checkbox>
        </div>
        <p className={styles.text}>
          Ich möchte ...
        </p>
        <div className={styles.buttonWrapper}>
          <button className={`${styles.button} ${styles.acceptButton}`} onClick={onAccept}>Alle</button>
          <button className={`${styles.button} ${styles.partlyAcceptButton}`} onClick={onPartlyAccept}>Ausgewählte</button>
          <button className={`${styles.button} ${styles.refuseButton}`} onClick={onRefuse}>Notwendige</button>
        </div>
        <p className={styles.text}>
          ... Cookies akzeptieren.
        </p>
      </div>
    </div>
  );
}