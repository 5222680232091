import { useState, useEffect, ChangeEvent, useRef } from 'react';

import styles from './multipleChoiceInput.module.css';
import { useOutsideAlerter } from '../../../hooks/useOutsideClickAlerter';

interface Props {
  label: string,
  options: string[],
  allowMultipleSelections?: boolean
  onSelectingOptions: (values: string[]) => void
}


export const MultipleChoiceInput = ({ label, options, allowMultipleSelections = false, onSelectingOptions }: Props) => {
  const [optionsOpen, setOptionsOpen] = useState<boolean>(false);
  const [labelText, setLabelText] = useState<string>(label);
  const [value, setValue] = useState<string>('');
  const [multipleValues, setMultipleValues] = useState<string[]>([]);
  useEffect(() => {
    if (value !== '') {
      setLabelText(`${label}: ${value}`)
      setOptionsOpen(false);
    } else if (multipleValues.length !== 0) {
      onSelectingOptions(multipleValues)
    }
  }, [value, multipleValues]);

  const changeMultipleValues = (e: ChangeEvent<HTMLInputElement>, option: string) => {
    let newValues = [];
    if (e.target.checked) {
      newValues = [...multipleValues, option];
    } else {
      newValues = multipleValues.filter(value => value !== option);
    }

    setMultipleValues(newValues);

    newValues.length !== 0 ? setLabelText(newValues.toString()) : setLabelText(label);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setOptionsOpen(false));

  return (
    <div ref={wrapperRef} className={styles.menuContainer}>
      <button className={styles.labelButton} onClick={() => setOptionsOpen(!optionsOpen)}>
        {labelText}
      </button>
      <ul className={styles.optionList} style={{ display: optionsOpen ? 'block' : 'none' }}>
        {
          allowMultipleSelections ?
            options.map(option =>
              <div key={option} className={styles.checkboxOptionContainer}>
                <input type='checkbox' className={styles.checkBox} onChange={e => changeMultipleValues(e, option)} />
                <li className={`${styles.option} ${styles.multipleValueOption}`}>{option}</li>
              </div>
            )
            :
            options.map(option => <li key={option} className={styles.option} onClick={() => setValue(option)}>{option}</li>)
        }
      </ul>
    </div>
  );
}