import { useRef, useState } from 'react';
import { useOutsideAlerter } from '../../../hooks/useOutsideClickAlerter';

import styles from './dropDownInput.module.css';

type Props = {
  onSelectingOption: (option: string) => void,
  options: string[],
  label: string,
  width?: number
}


export const DropDownInput = ({ onSelectingOption, options, label, width }: Props) => {
  const [selectedOption, setSelectedOption] = useState(label);
  const [optionsOpen, setOptionsOpen] = useState(false);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setOptionsOpen(false));

  return (
    <div className={styles.container}>
      <input className={styles.inputField} placeholder={selectedOption} readOnly onClick={() => setOptionsOpen(!optionsOpen)} style={{width: width}}/>
      {optionsOpen &&
        <ul className={styles.optionContainer} ref={wrapperRef}>
          {options.map(option =>
            <li key={option} className={styles.option} onClick={() => { onSelectingOption(option); setSelectedOption(option); setOptionsOpen(!optionsOpen) }}>
              {option}
            </li>
          )}
        </ul>
      }
    </div>
  )
}