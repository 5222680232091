import { useState } from 'react';
import { Link } from 'react-router-dom';

import styles from './cookies.module.css';
import { Checkbox } from '../../common/components/Checkbox';
import { getCookie } from '../../services/utils';

interface Cookie {
  name: string,
  example: string,
  expires: string,
  function: string,
  relevance: string,
  dsgvo: JSX.Element
}


export const Cookies = () => {
  const [preferenceCookies, setPreferenceCookies] = useState<boolean | undefined>(getCookie('cookieSettings')?.includes('"preferences":true') ? true : false);
  const [statisticsCookies, setStatisticsCookies] = useState<boolean | undefined>(getCookie('cookieSettings')?.includes('"statistics":true') ? true : false);
  const [saveButtonText, setSaveButtonText] = useState<string>('Speichern');
  const [saveButtonColor, setSaveButtonColor] = useState<string>('rgb(108, 83, 250)');

  const tableHeaders = ['Cookie-Name', 'Beispielwert', 'Ablaufzeit', 'Funktion', 'Relevanz', 'Datenschutzerklärung'];
  const cookies: Cookie[] = [
    {
      name: 'cookieSettings',
      example: '{"necessary": true, "preferences": false, "statistics": false}',
      expires: 'Persistent',
      function: 'Cookie-Einstellungen speichern',
      relevance: 'Notwendig',
      dsgvo: <Link to='../DSGVO'>Datenschutzerklärung</Link>
    },
    {
      name: 'userText',
      example: '"Verfasster Text im Kontaktformular"',
      expires: 'Session',
      function: 'Sichern von Textfeldern im Kontaktformular',
      relevance: 'Optional',
      dsgvo: <Link to='../DSGVO'>Datenschutzerklärung</Link>
    }
  ]

  const onSave = () => {
    document.cookie = `cookieSettings={"necessary":true, "preferences":${preferenceCookies}, "statistics":${statisticsCookies}};max-age=31536000`;

    setSaveButtonText('Gespeichert');
    setSaveButtonColor('rgb(88, 201, 73)');
    setTimeout(() => {
      setSaveButtonText('Speichern');
      setSaveButtonColor('rgb(108, 83, 250)');
    }, 1200);
  }

  return (
    <div className={styles.container}>
      <h1 className={styles.header}>Cookie-Einstellungen</h1>
      <h2>Du kannst hier jederzeit deine Cookie-Einstellungen verwalten:</h2>
      <div className={styles.checkboxContainer}>
        <Checkbox onChange={value => setPreferenceCookies(value.target.checked)} checked={preferenceCookies}>Präferenz-Cookies</Checkbox>
        <Checkbox onChange={value => setStatisticsCookies(value.target.checked)} checked={statisticsCookies}>Statistik-Cookies</Checkbox>
      </div>
      <button className={styles.saveButton} onClick={onSave} style={{ backgroundColor: `${saveButtonColor}` }}>{saveButtonText}</button>
      <h1 className={styles.header}>Cookie-Informationen</h1>
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <tr className={styles.row}>
            {tableHeaders.map((label) => <th key={label} className={styles.headerColumn}>{label}</th>)}
          </tr>
          {
            cookies.map(cookie => {
              return (
                <tr key={cookie.name} className={styles.row}>
                  <th className={styles.column}>{cookie.name}</th>
                  <th className={styles.column}>{cookie.example}</th>
                  <th className={styles.column}>{cookie.expires}</th>
                  <th className={styles.column}>{cookie.function}</th>
                  <th className={styles.column}>{cookie.relevance}</th>
                  <th className={styles.column}>{cookie.dsgvo}</th>
                </tr>
              );
            })
          }
        </table>
      </div>
    </div>
  );
}