import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
import {initializeAppCheck, ReCaptchaV3Provider} from 'firebase/app-check';

const firebaseConfig = {
  apiKey: 'AIzaSyA0vVNuk-BGGfDYJN7cMsP53hmFeycSjnQ',
  authDomain: 'as-persona.firebaseapp.com',
  databaseURL: 'https://as-persona-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'as-persona',
  storageBucket: 'as-persona.appspot.com',
  messagingSenderId: '511203090715',
  appId: '1:511203090715:web:f3aac01f7b18a4820f73be',
  measurementId: 'G-4W9TR19GR2'
};

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);

export const db = getFirestore(app);
initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LeadPUkAAAAALmzugGbO4Xo7AmaawhVivbLLNIu'),
  isTokenAutoRefreshEnabled: true
});