import { useState } from 'react';
import { addDoc, collection } from 'firebase/firestore';
import { getAnalytics, logEvent } from 'firebase/analytics';

import { db } from '../../firebase';
import styles from './applyCompany.module.css';
import { CompanyForm } from '../../interfaces/formData';
import { DropDownInput } from '../../common/components/DropDownInput';
import { SuccessBanner } from '../../common/containers/SuccessBanner';
import { PrivacyAgreementBanner } from '../../common/containers/PrivacyAgreementBanner';


export const ApplyCompany = () => {
  const [aggreed, setAggreed] = useState(false);
  const [companyData, setCompanyData] = useState<CompanyForm>(
    { professionalField: '', numberOfEmployees: '', startDate: '', requirements: '', salutation: '', fullName: '', companyName: '', postalCode: '', email: '', telephone: '' });
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);

  const handleSubmit = async () => {
    if (companyData.professionalField !== '' && companyData.numberOfEmployees !== '' && companyData.startDate !== '' && companyData.companyName !== '' && companyData.telephone !== '') {
      await
        addDoc(collection(db, 'companies'), companyData)
          .then(() => {
            setSuccess(true);
            const analytics = getAnalytics();
            logEvent(analytics, 'sign_up');
          })
          .catch(() => setErrorMessage('Beim übertragen der Daten ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihre Eingaben und Ihre Internetverbindung und versuchen Sie es erneut.'));
    } else {
      setErrorMessage('Es fehlen noch mit Sternchen (*) markierte Angaben.')
    }
  }

  return (
    <div>
      {!success ?
        <>
          <PrivacyAgreementBanner aggreed={aggreed} setAggreed={setAggreed} />
          <div className={styles.formContainer} style={{ display: aggreed ? 'flex' : 'none' }}>
            <h1>Ihre Personalanfrage:</h1>
            <DropDownInput label='Berufsfeld*' options={
              ['Industrie, Logistik & Handwerk', 'Kaufmännische Berufe', 'Fachhelfer', 'IT, Telekommunikation, Ingenieure',
                'Lager, Produktion', 'Gesundheitswesen', 'Service & Gastronomie', 'Callcenter', 'Finanzen']}
              onSelectingOption={val => setCompanyData({ ...companyData, professionalField: val })} />
            <input placeholder='*Anzahl Mitarbeiter' value={companyData.numberOfEmployees} type='number' max={10} min={0}
              onChange={e => e.target.value.length <= 2 && setCompanyData({ ...companyData, numberOfEmployees: e.target.value })} />
            <input placeholder='*Starttermin:' value={companyData.startDate} type='date'
              onChange={e => setCompanyData({ ...companyData, startDate: e.target.value })} />
            <textarea placeholder='Anmerkungen' value={companyData.requirements} maxLength={500}
              onChange={e => setCompanyData({ ...companyData, requirements: e.target.value })} />
            <h1>Ihre Kontaktdaten:</h1>
            <DropDownInput label='Anrede auswählen' options={['Herr', 'Frau', 'Divers']}
              onSelectingOption={val => { setCompanyData({ ...companyData, salutation: val }) }} />
            <input placeholder='Vorname Nachname' value={companyData.fullName} maxLength={30}
              onChange={e => setCompanyData({ ...companyData, fullName: e.target.value })} />
            <input placeholder='*Firma' value={companyData.companyName} maxLength={40}
              onChange={e => setCompanyData({ ...companyData, companyName: e.target.value })} />
            <input placeholder='Postleitzahl' value={companyData.postalCode} maxLength={10}
              onChange={e => setCompanyData({ ...companyData, postalCode: e.target.value })} />
            <input placeholder='E-Mail' value={companyData.email} maxLength={70}
              onChange={e => setCompanyData({ ...companyData, email: e.target.value })} />
            <input placeholder='*Telefonnummer' value={companyData.telephone} maxLength={20}
              onChange={e => setCompanyData({ ...companyData, telephone: e.target.value })} />
            <button className={styles.submitButton} onClick={handleSubmit}>Abschicken{' >>'}</button>
            {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
          </div>
        </>
        :
        <SuccessBanner />
      }
    </div>
  );
}