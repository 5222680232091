import { useState } from 'react';
import { addDoc, collection } from 'firebase/firestore';

import { db } from '../../firebase';
import styles from './contact.module.css';
import { SuccessBanner } from '../../common/containers/SuccessBanner';
import { ContactForm } from '../../interfaces/formData';
import { PrivacyAgreementBanner } from '../../common/containers/PrivacyAgreementBanner';


export const Contact = () => {
  const [contactData, setContactData] = useState<ContactForm>({ email: '', issue: '' });
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [aggreed, setAggreed] = useState(false);

  const handleSubmit = async () => {
    if (Object.values(contactData).every(x => x !== '')) {
      await addDoc(collection(db, 'contact'), contactData)
        .then(() => setSuccess(true))
        .catch(() => setErrorMessage('Beim übertragen der Daten ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihre Eingaben und Ihre Internetverbindung und versuchen Sie es erneut.'));
    } else {
      setErrorMessage('Es fehlen noch mit Sternchen (*) markierte Angaben.');
    }
  }


  return (
    <>
      {!success ?
        <>
          <PrivacyAgreementBanner aggreed={aggreed} setAggreed={setAggreed} />
          <div className={styles.formContainer} style={{ display: aggreed ? 'flex' : 'none' }}>
            <h1>Kontaktieren Sie uns einfach!</h1>
            <input type='email' placeholder='*E-Mail-Adresse' value={contactData.email} maxLength={70}
              onChange={e => setContactData({ ...contactData, email: e.target.value })} />
            <div>Bitte beschreiben Sie uns das Anliegen so genau wie möglich.</div>
            <textarea className={styles.formTextarea} placeholder='*Anfrage' value={contactData.issue} maxLength={500}
              onChange={e => setContactData({ ...contactData, issue: e.target.value })} />
            <button className={styles.formButton} onClick={handleSubmit}>Abschicken</button>
            {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
          </div>
        </>
        :
        <SuccessBanner />
      }
      <div className={styles.phoneNumberText}>Wir sind auch telefonisch für Sie erreichbar unter: 0541 506 760 1</div>
    </>
  );
}