import { useState } from 'react';

import styles from './applicationTips.module.css';


export const ApplicationTips = () => {
  const [infoText1Open, setInfoText1Open] = useState(false);
  const [infoText2Open, setInfoText2Open] = useState(false);
  const [infoText3Open, setInfoText3Open] = useState(false);
  const [infoText4Open, setInfoText4Open] = useState(false);
  const [infoText5Open, setInfoText5Open] = useState(false);

  return (
    <div className={styles.container}>
      <h1>Bewerbungstipps</h1>
      <h3>
        Hier sind einige Bewerbertipps, die Ihnen bei der Vorbereitung Ihrer
        Bewerbung und Vorstellungsgespräche helfen können:
      </h3>
      <ul className={`${styles.list} ${infoText1Open ? '' : styles.textFade}`} style={{maxHeight: infoText1Open ? '100%' : '60px', overflow: 'hidden'}}>
        <li className={styles.listItem}>
          Passen Sie Ihre Bewerbung an die Stelle an: Lesen Sie die
          Stellenausschreibung sorgfältig durch und stellen Sie sicher, dass
          Sie Ihre Bewerbungsunterlagen an die Anforderungen und
          Qualifikationen anpassen, die der Arbeitgeber sucht.
        </li>
        <li className={styles.listItem}>
          Verwenden Sie klare und prägnante Sprache: Vermeiden Sie es, in
          Ihrer Bewerbung lange Sätze und umständliche Formulierungen zu
          verwenden. Schreiben Sie klar und prägnant, um Ihre Fähigkeiten
          und Erfahrungen auf den Punkt zu bringen.
        </li>
        <li className={styles.listItem}>
          Betonen Sie Ihre Erfolge: Verwenden Sie in Ihrer Bewerbung
          konkrete Beispiele, um Ihre Leistungen und Erfolge zu
          demonstrieren. Zeigen Sie, wie Sie in früheren Jobs
          Herausforderungen gemeistert und Ziele erreicht haben.
        </li>
        <li className={styles.listItem}>
          Bereiten Sie sich auf das Vorstellungsgespräch vor: Informieren Sie
          sich über das Unternehmen, seine Kultur und die Stelle, für die Sie
          sich bewerben. Stellen Sie sicher, dass Sie Ihre Stärken und
          Schwächen kennen und in der Lage sind, Ihre Erfahrungen und
          Fähigkeiten effektiv zu präsentieren.
        </li>
        <li className={styles.listItem}>
          Seien Sie pünktlich und professionell: Stellen Sie sicher, dass Sie
          zum Vorstellungsgespräch pünktlich erscheinen und sich
          angemessen kleiden. Verhalten Sie sich höflich und professionell und
          bringen Sie eine positive Einstellung und Energie mit.
        </li>
        <li className={styles.listItem}>
          Stellen Sie Fragen: Nutzen Sie die Gelegenheit im
          Vorstellungsgespräch, um Fragen zu stellen und weitere
          Informationen über das Unternehmen und die Stelle zu erhalten.
          Zeigen Sie Interesse und Engagement für die Position.
        </li>
        <li className={styles.listItem}>
          Senden Sie eine Dankes-E-Mail: Nach dem Vorstellungsgespräch
          senden Sie eine Dankes-E-Mail an den Interviewer, um sich für die
          Gelegenheit zu bedanken und Ihre Interesse an der Stelle zu
          betonen.
        </li>
      </ul>
      <button className={styles.openInfoTextButton} onClick={() => setInfoText1Open(!infoText1Open)}>{infoText1Open ? '-' : '+'}</button>
      <h3>
        Ein guter Lebenslauf ist der Schlüssel zu einer erfolgreichen Bewerbung.
        Hier sind einige Tipps, wie Sie einen professionellen und überzeugenden
        Lebenslauf schreiben können:
      </h3>
      <ul className={`${styles.list} ${infoText2Open ? '' : styles.textFade}`} style={{maxHeight: infoText2Open ? '100%' : '60px', overflow: 'hidden'}}>
        <li className={styles.listItem}>
          Formatieren Sie den Lebenslauf übersichtlich und klar strukturiert:
          Verwenden Sie eine klare Schrift und ein professionelles Layout, um
          sicherzustellen, dass der Lebenslauf einfach zu lesen und zu
          verstehen ist.
        </li>
        <li className={styles.listItem}>
          Passen Sie den Lebenslauf an die Stelle an: Passen Sie Ihren
          Lebenslauf an die Stelle an, für die Sie sich bewerben. Stellen Sie
          sicher, dass Ihre Fähigkeiten und Erfahrungen den Anforderungen
          der Stelle entsprechen.
        </li>
        <li className={styles.listItem}>
          Betonen Sie Ihre Fähigkeiten und Erfahrungen: Stellen Sie Ihre
          Fähigkeiten und Erfahrungen in einem klaren und prägnanten
          Format dar. Verwenden Sie aktive Verben und quantitative
          Aussagen, um Ihre Leistungen und Erfolge zu demonstrieren.
        </li>
        <li className={styles.listItem}>
          Listen Sie Ihre Arbeitserfahrungen in umgekehrt-chronologischer
          Reihenfolge auf: Beginnen Sie mit Ihrer aktuellen oder letzten
          Anstellung und arbeiten Sie sich rückwärts durch Ihre
          Arbeitserfahrungen. Geben Sie für jede Anstellung den Jobtitel, den
          Arbeitgeber, die Beschäftigungsdaten und Ihre Aufgaben und
          Verantwortlichkeiten an.
        </li>
        <li className={styles.listItem}>
          Fügen Sie relevante Ausbildung, Zertifizierungen und Schulungen
          hinzu: Fügen Sie Ihre akademische Ausbildung, Zertifizierungen und
          Schulungen hinzu, die für die Stelle relevant sind.
        </li>
        <li className={styles.listItem}>
          Nennen Sie Ihre Fähigkeiten und Kenntnisse: Geben Sie Ihre
          Fähigkeiten und Kenntnisse an, einschließlich Ihrer
          Sprachkenntnisse, EDV-Kenntnisse und anderer relevanten
          Fähigkeiten.
        </li>
        <li className={styles.listItem}>
          Verwenden Sie keine Abkürzungen oder Fachjargon: Vermeiden Sie
          Abkürzungen oder Fachjargon, die für den Arbeitgeber
          möglicherweise nicht verständlich sind.
        </li>
        <li className={styles.listItem}>
          Verwenden Sie eine fehlerfreie Sprache: Korrekturlesen Sie Ihren
          Lebenslauf sorgfältig, um sicherzustellen, dass er frei von
          Rechtschreib- und Grammatikfehlern ist. Lassen Sie ihn auch von
          jemand anderem Korrekturlesen, um sicherzustellen, dass er
          professionell und fehlerfrei ist.
        </li>
        <li className={styles.listItem}>
          Halten Sie den Lebenslauf auf maximal zwei Seiten: Versuchen Sie,
          den Lebenslauf auf zwei Seiten zu beschränken, um sicherzustellen,
          dass er kurz und prägnant ist.
        </li>
      </ul>
      <button className={styles.openInfoTextButton} onClick={() => setInfoText2Open(!infoText2Open)}>{infoText2Open ? '-' : '+'}</button>
      <h3>
        Ein gutes Anschreiben sollte kurz und prägnant sein, Ihre Motivation für
        die Stelle darlegen und Ihre Fähigkeiten und Erfahrungen hervorheben.
        Hier sind einige Tipps, wie Sie ein überzeugendes Anschreiben für Ihre
        Bewerbung schreiben können:
      </h3>
      <ul className={`${styles.list} ${infoText3Open ? '' : styles.textFade}`} style={{maxHeight: infoText3Open ? '100%' : '60px', overflow: 'hidden'}}>
        <li className={styles.listItem}>
          Adressieren Sie das Anschreiben an die richtige Person: Wenn
          möglich, finden Sie den Namen des Personalverantwortlichen oder
          des direkten Vorgesetzten heraus und adressieren Sie das
          Anschreiben an diese Person.
        </li>
        <li className={styles.listItem}>
          Beginnen Sie mit einer prägnanten Einleitung: Beginnen Sie das
          Anschreiben mit einer kurzen Einleitung, in der Sie sich vorstellen
          und erwähnen, für welche Stelle Sie sich bewerben.
        </li>
        <li className={styles.listItem}>
          Betonen Sie Ihre Motivation für die Stelle: Beschreiben Sie Ihre
          Motivation für die Stelle und warum Sie der richtige Kandidat für die
          Position sind. Vermeiden Sie es, in Ihrem Anschreiben auf Ihre
          persönlichen Ziele einzugehen.
        </li>
        <li className={styles.listItem}>
          Verweisen Sie auf Ihre Fähigkeiten und Erfahrungen: Verweisen Sie
          auf Ihre relevanten Fähigkeiten und Erfahrungen, die für die Stelle
          relevant sind. Geben Sie konkrete Beispiele für Ihre Erfolge und
          Leistungen.
        </li>
        <li className={styles.listItem}>
          Passen Sie das Anschreiben an die Stellenausschreibung an: Passen
          Sie Ihr Anschreiben an die Stellenausschreibung an, indem Sie auf
          die spezifischen Anforderungen und Qualifikationen eingehen, die
          der Arbeitgeber sucht.
        </li>
        <li className={styles.listItem}>
          Schließen Sie das Anschreiben mit einer klaren
          Handlungsaufforderung ab: Schließen Sie das Anschreiben mit einer
          klaren Handlungsaufforderung ab, wie z.B. einer Einladung zum
          Vorstellungsgespräch.
        </li>
        <li className={styles.listItem}>
          Korrekturlesen Sie das Anschreiben sorgfältig: Korrekturlesen Sie
          das Anschreiben sorgfältig, um sicherzustellen, dass es frei von
          Rechtschreib- und Grammatikfehlern ist. Lassen Sie es von jemand
          anderem Korrekturlesen, um sicherzustellen, dass es professionell
          und fehlerfrei ist.
        </li>
      </ul>
      <button className={styles.openInfoTextButton} onClick={() => setInfoText3Open(!infoText3Open)}>{infoText3Open ? '-' : '+'}</button>
      <h3>
        Hier sind einige Tipps, wie Sie sich auf ein Vorstellungsgespräch
        vorbereiten können:
      </h3>
      <ul className={`${styles.list} ${infoText4Open ? '' : styles.textFade}`} style={{maxHeight: infoText4Open ? '100%' : '60px', overflow: 'hidden'}}>
        <li className={styles.listItem}>
          Recherchieren Sie das Unternehmen: Informieren Sie sich über das
          Unternehmen, bei dem Sie sich bewerben, indem Sie die Website
          des Unternehmens besuchen und nach aktuellen Nachrichten über
          das Unternehmen suchen.
        </li>
        <li className={styles.listItem}>
          Bereiten Sie Antworten auf häufig gestellte Fragen vor: Überlegen
          Sie sich im Voraus, welche Fragen Ihnen während des
          Vorstellungsgesprächs gestellt werden könnten, und bereiten Sie
          Antworten vor.
        </li>
        <li className={styles.listItem}>
          Üben Sie das Vorstellungsgespräch: Üben Sie das
          Vorstellungsgespräch mit einem Freund oder Familienmitglied, um
          sich auf die Fragen und Situationen, die während des Interviews
          auftreten könnten, vorzubereiten.
        </li>
        <li className={styles.listItem}>
          Entscheiden Sie, was Sie anziehen werden: Entscheiden Sie, was Sie
          während des Vorstellungsgesprächs tragen werden, und sorgen Sie
          dafür, dass Ihre Kleidung sauber und ordentlich ist.
        </li>
        <li className={styles.listItem}>
          Bringen Sie alle notwendigen Unterlagen mit: Bringen Sie alle
          notwendigen Unterlagen, wie z.B. Ihren Lebenslauf und Zeugnisse,
          zum Vorstellungsgespräch mit.
        </li>
        <li className={styles.listItem}>
          Seien Sie pünktlich: Stellen Sie sicher, dass Sie pünktlich zum
          Vorstellungsgespräch erscheinen, indem Sie im Voraus die Route
          zum Ort des Gesprächs planen und frühzeitig losgehen.
        </li>
        <li className={styles.listItem}>
          Stellen Sie Fragen: Bereiten Sie im Voraus Fragen vor, die Sie
          während des Vorstellungsgesprächs stellen möchten, um zu zeigen,
          dass Sie an der Position und dem Unternehmen interessiert sind.
        </li>
        <li className={styles.listItem}>
          Bleiben Sie positiv: Versuchen Sie, während des
          Vorstellungsgesprächs positiv und selbstbewusst aufzutreten und
          zeigen Sie Ihr Interesse an der Stelle und dem Unternehmen.
        </li>
        <li className={styles.listItem}>
          Follow-up: Nach dem Vorstellungsgespräch, schicken Sie eine
          Dankes-Email an die Person, die das Gespräch mit Ihnen geführt hat,
          um sich für die Gelegenheit zu bedanken und Ihr Interesse an der
          Stelle zu bekräftigen.
        </li>
      </ul>
      <button className={styles.openInfoTextButton} onClick={() => setInfoText4Open(!infoText4Open)}>{infoText4Open ? '-' : '+'}</button>
      <h3>
        Hier sind einige Tipps, wie ein gutes Bewerbungsschreiben aussehen
        sollte:
      </h3>
      <ul className={`${styles.list} ${infoText5Open ? '' : styles.textFade}`} style={{maxHeight: infoText5Open ? '100%' : '60px', overflow: 'hidden'}}>
        <li className={styles.listItem}>
          Persönlicher Ansprechpartner: Versuchen Sie, den Namen des
          Personalverantwortlichen oder des direkten Vorgesetzten
          herauszufinden und adressieren Sie das Anschreiben an diese
          Person.
        </li>
        <li className={styles.listItem}>
          Kurze und prägnante Einleitung: Beginnen Sie das Anschreiben mit
          einer kurzen Einleitung, in der Sie sich vorstellen und erwähnen, für
          welche Stelle Sie sich bewerben.
        </li>
        <li className={styles.listItem}>
          Betonen Sie Ihre Motivation für die Stelle: Beschreiben Sie Ihre
          Motivation für die Stelle und warum Sie der richtige Kandidat für die
          Position sind. Vermeiden Sie es, in Ihrem Anschreiben auf Ihre
          persönlichen Ziele einzugehen.
        </li>
        <li className={styles.listItem}>
          Verweisen Sie auf Ihre Fähigkeiten und Erfahrungen: Verweisen Sie
          auf Ihre relevanten Fähigkeiten und Erfahrungen, die für die Stelle
          relevant sind. Geben Sie konkrete Beispiele für Ihre Erfolge und
          Leistungen.
        </li>
        <li className={styles.listItem}>
          Passen Sie das Anschreiben an die Stellenausschreibung an: Passen
          Sie Ihr Anschreiben an die Stellenausschreibung an, indem Sie auf
          die spezifischen Anforderungen und Qualifikationen eingehen, die
          der Arbeitgeber sucht.
        </li>
        <li className={styles.listItem}>
          Schließen Sie das Anschreiben mit einer klaren
          Handlungsaufforderung ab: Schließen Sie das Anschreiben mit einer
          klaren Handlungsaufforderung ab, wie z.B. einer Einladung zum
          Vorstellungsgespräch.
        </li>
        <li className={styles.listItem}>
          Korrekturlesen Sie das Anschreiben sorgfältig: Korrekturlesen Sie
          das Anschreiben sorgfältig, um sicherzustellen, dass es frei von
          Rechtschreib- und Grammatikfehlern ist. Lassen Sie es von jemand
          anderem Korrekturlesen, um sicherzustellen, dass es professionell
          und fehlerfrei ist.
        </li>
        <li className={styles.listItem}>
          Halten Sie sich an den Business-Style: Verwenden Sie eine formelle
          Sprache, einen professionellen Ton und achten Sie darauf, dass Sie
          keine Rechtschreib- oder Grammatikfehler machen.
        </li>
        <li className={styles.listItem}>
          Halten Sie sich kurz: Versuchen Sie, Ihr Bewerbungsschreiben auf
          eine Seite zu beschränken und vermeiden Sie lange Absätze oder
          unnötige Informationen.
        </li>
        <li className={styles.listItem}>
          Heben Sie Ihre Stärken hervor: Betonen Sie, welche
          Fähigkeiten und Stärken Sie für die Stelle mitbringen und wie Sie
          dazu beitragen können, das Unternehmen zu unterstützen.
        </li>
      </ul>
      <button className={styles.openInfoTextButton} onClick={() => setInfoText5Open(!infoText5Open)}>{infoText5Open ? '-' : '+'}</button>
    </div>
  );
}