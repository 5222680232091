import styles from './communication.module.css';
import laptop from '../../Assets/laptop.jpg';
import different_devices from '../../Assets/different_devices.jpg';


export const Communication = () => {
  return (
    <div className={styles.container}>
      <div className={styles.recruitementChannels}>
        <h1 className={styles.header2}>Nicht nur zeitgemäße Kommunikation,<br/>sondern auch modernste Rekrutierungs&#xAD;kanäle</h1>
        <img className={styles.image} src={different_devices} alt=''/>
        <p className={styles.description}>Ob unser Netzwerk, Google Ads Instagram, LinkedIn, Xing, Indeed, wir setzen alle Hebel in Bewegung, um sie als Unternehmer zufrieden zu stellen.</p>
      </div>
      <div className={styles.modernCommunication}>
        <h1 className={styles.header}>Immer für Sie da. Immer für Sie erreichbar.<br/>Modernste Kommunikation ist da selbstverständlich.</h1>
        <img className={styles.image} src={laptop} alt=''/>
        <h3>Schnell und Effizient</h3>
        <h3>Egal ob Microsoft Teams, Google Works, Zoom oder Facetime.<br/>Sie wählen das Medium, was Ihnen am meisten zusagt.</h3>
      </div>
    </div>
  );
}