import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';

import styles from './footer.module.css';
import logo_black from '../../assets/logo_black.png';
import { Link } from 'react-router-dom';

export const Footer = () => {

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    // @ts-ignore
    const platform = navigator?.userAgentData?.platform || navigator?.platform;
    const isAppleDevice = /Mac|iPod|iPhone|iPad/.test(platform);
    if (isAppleDevice) window.scrollTo(0, 0);
  };

  return (
    <div className={styles.footer}>
      <div className={styles.toTop} onClick={scrollToTop}>Zurück zum Start</div>
      <div className={styles.contentWrapper}>
        <div className={styles.grid}>
          <div className={styles.brandRow}>
            <img src={logo_black} className={styles.logo} alt='decorative' onClick={() => window.location.href = '/'} />
            <h4>AS-Persona <br />Dein Personalvermittler</h4>
            {/* Socialmedia-Icons
                 <div className={styles.socialIcons}>
              <FontAwesomeIcon className={`${styles.icon} ${styles.facebookIcon}`} icon={faFacebook} />
              <div className={styles.pseudoElement}></div>
              <FontAwesomeIcon className={`${styles.icon} ${styles.instagramIcon}`} icon={faInstagram} />
              <div className={styles.pseudoElement}></div>
              <FontAwesomeIcon className={`${styles.icon} ${styles.twitterIcon}`} icon={faTwitter} />
            </div>                  
          */}
          </div>
          <hr className={styles.seperator} />
          <div className={styles.linkContainer}>
            <Link className={styles.link} to='/companyInfos'>Für Unternehmen</Link>
            <br /><br />
            <Link className={styles.link} to='/employeeInfos'>Für Bewerber</Link>
            <br /><br />
            <Link className={styles.link} to='/applyEmployee'>Jetzt Bewerben</Link>
            <br /><br />
            <Link className={styles.link} to='/applyCompany'>Personal finden</Link>
          </div>
          <div className={styles.linkContainer}>
            <Link className={styles.link} to='/credits'>Impressum</Link>
            <br /><br />
            <Link className={styles.link} to='/cookies'>Cookies</Link>
            <br /><br />
            <Link className={styles.link} to='/dsgvo'>Datenschutz</Link>
            <br /><br />
            <Link className={styles.link} to='/contact'>Kontakt</Link>
          </div>
        </div>
      </div>
      <div className={styles.copyrightText}>Copyright &#169; 2023 AS. Alle Rechte vorbehalten.</div>
    </div>
  );
} 