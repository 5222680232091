import styles from './checkbox.module.css';

type Props = {
  children: string | JSX.Element,
  checked?: boolean,
  readOnly?: boolean,
  onChange?: (value: any) => void
}


export const Checkbox = ({ children, checked, readOnly, onChange }: Props) => {
  return (
    <label className={styles.checkboxLabel}>
      <input type='checkbox' className={styles.checkbox} checked={checked} readOnly={readOnly}
        onChange={onChange} />
      {children}
    </label>
  );
}