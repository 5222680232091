import { useState } from 'react';
import { addDoc, collection } from 'firebase/firestore';
import { getAnalytics, logEvent } from 'firebase/analytics';

import { db } from '../../firebase';
import styles from './applyEmployee.module.css';
import { SuccessBanner } from '../../common/containers/SuccessBanner';
import { PrivacyAgreementBanner } from '../../common/containers/PrivacyAgreementBanner';
import { DropDownInput } from '../../common/components/DropDownInput';
import { MultipleChoiceInput } from '../../common/components/MultipleChoiceInput';
import { EmployeeForm } from '../../interfaces/formData';


export const ApplyEmployee = () => {
  const [aggreed, setAggreed] = useState(false);
  const [employeeData, setEmployeeData] = useState<EmployeeForm>({
    salutation: '', fullName: '', birthdate: '', placeOfBirth: '', email: '', phone: '', street: '', postalCode: '', place: '',
    jobWish: '', shift: '', employmentType: '', salaryWish: '', otherWishes: '', publicTransport: '', drivingLicenses: [], maxReach: '', otherMobility: '',
    educations: [], educationInfos: '', jobExperience: [], jobExperienceInfos: '', currentJob: ''
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);

  const handleSubmit = async () => {
    const analytics = getAnalytics();
    logEvent(analytics, 'sign_up');
    if (employeeData.email !== '') {
      let drivingLicensesString = employeeData.drivingLicenses.toString();
      let educationsString = employeeData.educations.toString();
      let jobExperienceString = employeeData.jobExperience.toString();
      let sendableData = { ...employeeData, drivingLicenses: drivingLicensesString, educations: educationsString, jobExperience: jobExperienceString }
      await
        addDoc(collection(db, 'employees'), sendableData)
          .then(() => {
            setSuccess(true);
          })
          .catch(() => setErrorMessage('Beim übertragen der Daten ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihre Eingaben & Ihre Internetverbindung und versuchen Sie es erneut.'));
    } else {
      setErrorMessage('Es fehlen noch mit Sternchen (*) markierte Pflichtangaben.');
    }
  }

  return (
    <div>
      {!success ?
        <>
          <PrivacyAgreementBanner aggreed={aggreed} setAggreed={setAggreed} />
          <div className={styles.formContainer} style={{ display: aggreed ? 'flex' : 'none' }}>
            <h3>Schicken Sie uns Ihren Lebenslauf an <a href="mailto: bewerbung@aspersona.de">bewerbung@aspersona.de</a>, oder füllen Sie das folgende Formular aus:</h3>
            <h1>Persönliche Angaben:</h1>
            <DropDownInput label='Anrede auswählen...' options={['Herr', 'Frau', 'Divers']}
              onSelectingOption={val => { setEmployeeData({ ...employeeData, salutation: val }) }} />
            <input placeholder='Vorname Nachname' value={employeeData.fullName} maxLength={30}
              onChange={e => setEmployeeData({ ...employeeData, fullName: e.target.value })} />
            <input placeholder='Geburtsdatum' type='date' value={employeeData.birthdate}
              onChange={e => setEmployeeData({ ...employeeData, birthdate: e.target.value })} />
            <input placeholder='*Email' value={employeeData.email} maxLength={70}
              onChange={e => setEmployeeData({ ...employeeData, email: e.target.value })} />
            <br />
            <input placeholder='Telefonnummer' value={employeeData.phone} maxLength={20}
              onChange={e => setEmployeeData({ ...employeeData, phone: e.target.value })} />
            <input placeholder='Geburtsort' value={employeeData.placeOfBirth} maxLength={60}
              onChange={e => setEmployeeData({ ...employeeData, placeOfBirth: e.target.value })} />
            <input placeholder='Straße + Hausnummer' value={employeeData.street} maxLength={50}
              onChange={e => setEmployeeData({ ...employeeData, street: e.target.value })} />
            <input placeholder='Postleitzahl' value={employeeData.postalCode} maxLength={10}
              onChange={e => setEmployeeData({ ...employeeData, postalCode: e.target.value })} />
            <input placeholder='Ort' value={employeeData.place} maxLength={60}
              onChange={e => setEmployeeData({ ...employeeData, place: e.target.value })} />
            <br /><br />
            <h1>Ihre Wünsche:</h1>
            <input placeholder='Jobwunsch' value={employeeData.jobWish} maxLength={100}
              onChange={e => setEmployeeData({ ...employeeData, jobWish: e.target.value })} />
            <DropDownInput label='Vollzeit/Teilzeit auswählen...' options={['Vollzeit', 'Teilzeit', 'Vollzeit oder Teilzeit']}
              onSelectingOption={val => { setEmployeeData({ ...employeeData, employmentType: val }) }} />
            <DropDownInput label='Schichtzeit auswählen...' options={['Frühschicht', 'Tagschicht', 'Spätschicht', 'Nachtschicht']}
              onSelectingOption={val => { setEmployeeData({ ...employeeData, shift: val }) }} />
            <input placeholder='Bruttomonatsgehalt' value={employeeData.salaryWish} type='number' step={100}
              onChange={e => e.target.value.length <= 7 && setEmployeeData({ ...employeeData, salaryWish: e.target.value })} /> €
            <textarea placeholder='Hier können Sie weitere Anmerkungen geben...' value={employeeData.otherWishes} maxLength={200}
              onChange={e => setEmployeeData({ ...employeeData, otherWishes: e.target.value })} />
            <br /><br />
            <h1>Mobilität:</h1>
            <DropDownInput label='Auf Öffis angewiesen?' options={['Auf Öffis angewiesen', 'Nicht auf Öffis angewiesen']}
              onSelectingOption={val => { setEmployeeData({ ...employeeData, publicTransport: val }) }} />
            <MultipleChoiceInput label='Fahrerlaubnis(se)...' options={['Auto B', 'Staplerschein', 'Kranschein', 'Roller', 'Bus D', 'Lastwagen CE', 'Lastwagen C', 'Lastwagen C1']}
              onSelectingOptions={options => setEmployeeData({ ...employeeData, drivingLicenses: options })} allowMultipleSelections />
            <MultipleChoiceInput label='Fahrzeuge in Besitz...' options={['PKW', 'Motorrad', 'Roller', 'E-Bike']}
              onSelectingOptions={options => setEmployeeData({ ...employeeData, drivingLicenses: options })} allowMultipleSelections />
            <DropDownInput label='Maximaler Einsatzbereich (Kilometer)' width={300} onSelectingOption={val => { setEmployeeData({ ...employeeData, maxReach: val }) }} options={
              ['Nur Innerorts', 'Einsatzbereich max. 5km', 'Einsatzbereich max. 10km', 'Einsatzbereich max. 15km', 'Einsatzbereich max. 20km', 'Einsatzbereich max. 25km',
                'Einsatzbereich max. 30km', 'Einsatzbereich max. 40km', 'Einsatzbereich max. 50km', 'Einsatzbereich max. Über 50km']} />
            <textarea placeholder='Hier können Sie weitere Anmerkungen geben...' value={employeeData.otherMobility} maxLength={200}
              onChange={e => setEmployeeData({ ...employeeData, otherMobility: e.target.value })} />
            <br /><br />
            <h1>Bisherige Laufbahn:</h1>
            <MultipleChoiceInput label='Abgeschlossene Ausbildung...' options={['Kaufmannsgehilfenbrief', 'Gesellenbrief', 'Bachelor', 'Sonstiges']}
              onSelectingOptions={options => setEmployeeData({ ...employeeData, educations: options })} allowMultipleSelections />
            <textarea placeholder='Anmerkungen zu den Ausbildungen...' value={employeeData.educationInfos} maxLength={200}
              onChange={e => setEmployeeData({ ...employeeData, educationInfos: e.target.value })} />
            <br />
            <MultipleChoiceInput label='Berufserfahrung...' options={['Lager/Produktion', 'Kaufmännischer Bereich', 'Handwerklicher Bereich', 'Sonstiges']}
              onSelectingOptions={options => setEmployeeData({ ...employeeData, jobExperience: options })} allowMultipleSelections />
            <textarea placeholder='Anmerkungen zur Berufserfahrung...' value={employeeData.jobExperienceInfos} maxLength={200}
              onChange={e => setEmployeeData({ ...employeeData, jobExperienceInfos: e.target.value })} />
            <textarea placeholder='Falls Sie sich zur Zeit in einem Arbeitsverhältnis befinden, geben Sie dieses hier an...' value={employeeData.currentJob} maxLength={100}
              onChange={e => setEmployeeData({ ...employeeData, currentJob: e.target.value })} />
            <button className={styles.submitButton} onClick={handleSubmit}>Abschicken{' >>'}</button>
            {
              errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>
            }
          </div>
        </>
        :
        <SuccessBanner />
      }
    </div>
  );
}
