import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { MainMenu } from './common/containers/MainMenu';
import { Home } from './pages/Home';
import { Cookies } from './pages/Cookies';
import { Credits } from './pages/Credits';
import { DSGVO } from './pages/DSGVO';
import { Contact } from './pages/Contact';
import { Footer } from './common/containers/Footer';
import { EmployeeInfos } from './pages/EmployeeInfos';
import { CompanyInfos } from './pages/CompanyInfos';
import { ApplyEmployee } from './pages/ApplyEmployee';
import { ApplyCompany } from './pages/ApplyCompany';
import { ScrollToTop } from './common/components/ScrollToTop';
import { ConsentCookies } from './common/containers/ConsentCookies';
import { Meeting } from './pages/Meeting';
import { ApplicationTips } from './pages/ApplicationTips';


export const App = () => {
  return (
    <Router>
      <ScrollToTop>
        <ConsentCookies />
        <Routes>
          <Route index element={<Home />} />
          <Route path='applyEmployee' element={<ApplyEmployee />} />
          <Route path='applyCompany' element={<ApplyCompany />} />
          <Route path='cookies' element={<Cookies />} />
          <Route path='contact' element={<Contact />} />
          <Route path='credits' element={<Credits />} />
          <Route path='dsgvo' element={<DSGVO />} />
          <Route path='employeeInfos' element={<EmployeeInfos />} />
          <Route path='companyInfos' element={<CompanyInfos />} />
          <Route path='meeting' element={<Meeting />} />
          <Route path='applicationTips' element={<ApplicationTips />} />
        </Routes>
        <MainMenu />
        <Footer />
      </ScrollToTop>
    </Router>
  );
}