import { useState } from 'react';
import { addDoc, collection } from 'firebase/firestore';

import { db } from '../../firebase';
import styles from './meeting.module.css';
import { MeetingForm } from '../../interfaces/formData';
import { SuccessBanner } from '../../common/containers/SuccessBanner';
import { PrivacyAgreementBanner } from '../../common/containers/PrivacyAgreementBanner';

export const Meeting = () => {
  const [meetingData, setMeetingData] = useState<MeetingForm>({ company: '', contactPerson: '', phoneNumber: '', recallDate: '', recallTime: '' });
  const [errorMessage, setErrorMessage] = useState('');
  const [successfulDataSubmition, setSuccessfulDataSubmition] = useState(false);
  const [aggreed, setAggreed] = useState(false);

  const handleSubmit = async () => {
    if (Object.values(meetingData).every(x => x !== '')) {
      await addDoc(collection(db, 'dates'), meetingData)
        .then(() => setSuccessfulDataSubmition(true))
        .catch(() => setErrorMessage('Beim übertragen der Daten ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihre Eingaben und Ihre Internetverbindung und versuchen Sie es erneut.'));
    } else {
      setErrorMessage('Es fehlen noch mit Sternchen (*) markierte Angaben.');
    }
  }

  return (
    <>
      <PrivacyAgreementBanner aggreed={aggreed} setAggreed={setAggreed} />
      <div className={styles.container}>
        {!successfulDataSubmition ?
          <div className={styles.formContainer} style={{ display: aggreed ? 'flex' : 'none' }}>
            <h1>Jetzt Rückruf vereinbaren:</h1>
            <p>Sie können uns einfach Ihren Firmennamen, einen Ansprechpartner und ein Rückrufdatum mit Uhrzeit hinterlassen. Wir rufen Sie gerne zurück!</p>
            <input placeholder='*Firma' value={meetingData.company} maxLength={40}
              onChange={e => setMeetingData({ ...meetingData, company: e.target.value })} />
            <input placeholder='*Ansprechpartner' value={meetingData.contactPerson} maxLength={30}
              onChange={e => setMeetingData({ ...meetingData, contactPerson: e.target.value })} />
            <input placeholder='*Telefonnummer' value={meetingData.phoneNumber} maxLength={20}
              onChange={e => setMeetingData({ ...meetingData, phoneNumber: e.target.value })} />
            <input placeholder='*Datum:' value={meetingData.recallDate} type='date'
              onChange={e => setMeetingData({ ...meetingData, recallDate: e.target.value })} />
            <input placeholder='*Uhrzeit:' value={meetingData.recallTime} type='time'
              onChange={e => setMeetingData({ ...meetingData, recallTime: e.target.value })} />
            <button className={styles.submitButton} onClick={handleSubmit}>Abschicken{' >>'}</button>
            {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
          </div>
          :
          <SuccessBanner />
        }
      </div>
    </>
  );
}