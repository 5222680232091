import { Link } from 'react-router-dom';

import styles from './credits.module.css';


export const Credits = () => {
  return (
    <div className={styles.container}>
      <h1>Impressum</h1>
      <hr className={styles.divisor} />
      <p>
        <h3>Angaben gemäß § 5 TMG</h3>
      </p>
      <p>
        <h4>Anschrift:</h4>
        Andreas Stob<br />
        Tirolerstraße 7, <br />
        49082 Osnabrück</p>
      <p>
        <h4>AS</h4>
        Vertreten durch: <br />
        Andreas Stob</p>
      <p>
        <h4>Kontakt:</h4>
        Telefon: 0541 506 760 1 <br />
        E-Mail: info@aspersona.de
      </p>
      <p>
        <h4>Umsatzsteuer-ID:</h4>
        DE360058501
      </p>
      <p><Link to='../dsgvo'>Datenschutz</Link></p>
      <p><Link to='../cookies'>Cookies</Link></p>
      <p>
        <h4>Urheberrecht:</h4>
        Nachweis über die Herkunft der verwendeten Bilder: <br />
        stock.adobe.com,
        Pixabay.com
      </p>
      <hr className={styles.divisor} />
    </div>
  );
}