import styles from './infoText.module.css';

type Props = {
  header: string,
  children: any
}

export const InfoText = ({ header, children }: Props) => {

  return (
    <div className={styles.infoText}>
      <h1>{header}</h1>
      <p>
        {children}
      </p>
    </div>
  );
}