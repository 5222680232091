import { Link } from 'react-router-dom';

import styles from './employeeInfos.module.css';
import woman_thinking_2 from './Assets/woman_thinking_2.jpg';
import { InfoText } from '../../common/components/InfoText';



export const EmployeeInfos = () => {
  return (
    <div>
      <div className={styles.landingImageContainer}>
        <div className={styles.landingImage}>
          <div className={styles.textContainer}>
            <div className={styles.textDescription}>Was ist der Vorteil einer Personalvermittlung<br /> und eignet sie sich auch für mich?</div>
          </div>
          <div className={styles.linkContainer}>
            <Link className={styles.tileHeader} to='/applyEmployee'>Jetzt Bewerben & Traumjob finden{' >>'}</Link>
          </div>
        </div>
      </div>
      <InfoText header='Personalvermittlung = Zeitarbeit?'>
        Nein, die Personalvermittlung ist keine Zeitarbeit. Bei der Personalvermittlung werden Sie direkt in dem Unternehmen
        angestellt, für das wir Mitarbeiter suchen.
        <p>Bei der Zeitarbeit wird eine Person von einem Zeitarbeitsunternehmen eingestellt und dann an ein anderes Unternehmen verliehen,
          um dort für einen begrenzten Zeitraum zu arbeiten. Die Arbeitskräfte sind dabei Angestellte des Zeitarbeitsunternehmens und nicht
          des Unternehmens, für das sie arbeiten. Die Bezahlung und die Arbeitsbedingungen werden durch den Zeitarbeitsvertrag geregelt.
        </p>
        <p>
          Bei der Personalvermittlung hingegen sucht ein Personalvermittlungsunternehmen im Auftrag eines Arbeitgebers nach passenden Kandidaten
          für eine dauerhafte Anstellung in dessen Unternehmen. Die Arbeitskräfte werden direkt vom Arbeitgeber eingestellt und sind dessen
          Angestellte. Das Personalvermittlungsunternehmen fungiert als Vermittler zwischen Arbeitgeber und Arbeitnehmer und erhält dafür
          peine Vermittlungsprovision.
        </p>
      </InfoText>
      <div className={styles.imgTextPairContainer}>
        <img className={styles.imgTextPairImg} src={woman_thinking_2} alt='' />
        <ul className={styles.imgTextPairList}>
          <h2>Ihre Vorteile auf einen Blick:</h2>
          <li>- Für Bewerber kostenfrei.</li>
          <li>- Direkte Festanstellung bei Kundenunternehmen.</li>
          <li>- Jobs, die teilweise nicht öffentlich ausgeschrieben sind.</li>
          <li>- Hintergrundinformationen, die Sie über eine Stellenausschreibung im Unternehmen nicht erfahren.</li>
          <li>- Sie schreiben nur eine Bewerbung, wir machen den Rest.</li>
          <li>- Wir suchen nach geeigneten Stellen für Sie und berücksichtigen Ihre Wünsche.</li>
          <li>- Die Möglichkeit als Quereinsteiger erfolgreicher zu werden</li>
          <li>- Bewerbungstipps und Vorbereitungen auf Vorstellungsgespräche</li>
        </ul>
      </div>
      <InfoText header='Die Kosten einer Personalvermittlung'>
        Eine Personalvermittlung ist für Sie als Bewerber völlig kostenfrei.
        <p>
          AS übernimmt teilweise oder vollständig den Rekrutierungsprozess für Unternehmen. Wir fungieren also als
          Vermittler zwischen Ihnen und Ihrem neuen Arbeitgeber. Unser Ziel ist es, Sie als Kanditaten mit dem passenden Unternehmen zusammenzuführen.
          Das bedeutet für Sie, dass Sie direkt bei dem Unternehmen angestellt werden und nicht bei uns, der Personalvermittlung.
        </p>
      </InfoText>
    </div>
  );
}