import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import styles from './privacyAgreementBanner.module.css';

type Props = {
  aggreed: boolean,
  setAggreed: (aggreed: boolean) => void
}


export const PrivacyAgreementBanner = ({ aggreed, setAggreed }: Props) => {
  const [infoTextOpen, setInfoTextOpen] = useState(false);

  return (
    <div className={styles.aggreementContainer}>
      <h1>Datenverarbeitung</h1>
      <p className={`${styles.aggreementDescription} ${infoTextOpen ? '' : styles.descriptionTextFade}`} style={{maxHeight: infoTextOpen ? '100%' : '40px', overflow: 'hidden'}}>
        Sie bestätigen die Richtigkeit und Vollständigkeit der von Ihnen angegebenen Daten durch das Auswählen der Option 'Ja, ich stimme Datenverarbeitung meiner Daten zu.'.
        Werden einzelne Daten bewusst unvollständig angegeben, erlaubt dies dem Arbeitgeber die Anfechtung des Arbeitsvertrages wegen arglistiger Täuschung.
        Laut §26 Bundesdatenschutzgesetz dürfen personenbezogene Daten zum Zweck des Beschäftigungsverhältnisses verarbeitet werden.
        Durch das Auswählen der Option 'Ja, ich stimme der Datenverarbeitung meiner Daten zu.' geben Sie uns die Einwilligung zur Verarbeitung Ihrer Daten und die Einwilligung dafür,
        dass Ihre Daten an berechtigte Dritte weitergegeben werden dürfen. AS speichert Ihre Bewerberdaten für zukünftige offene Stellen in einer Bewerberdatenbank.
        Durch das Auswählen der Option 'Ja, ich stimme der Datenverarbeitung zu.' geben Sie uns Ihre Einwilligung dazu.
        Dies dient dazu, um das Bewerbungsverfahren fortsetzen zu können und um mit Ihnen Kontakt aufnehmen zu können.
        Ihre durch das Auswählen der Option 'Ja ich stimme der Datenverarbeitung meiner Daten zu.' erteilte Einwilligung können sie jederzeit widerrufen.
        Möglich ist das sowohl per Post an die: AS Tirolerstraße 7, 49082 Osnabrück, als auch schriftlich per E-Mail an: info@aspersona.de.
        Wir weisen Sie darauf hin, dass Sie jederzeit die hiermit erteilte Einwilligung zur Datenverarbeitung widerrufen können.
        <br />
        Unsere vollständigen Datenschutzbedingungen finden Sie ebenfalls unter <Link to='/dsgvo'>Dsgvo</Link>.
      </p>
      <button className={styles.openInfoTextButton} onClick={() => setInfoTextOpen(!infoTextOpen)}>{infoTextOpen ? '-' : '+'}</button>
      <div>
        <button className={styles.aggreementButton} onClick={() => setAggreed(true)}
          style={aggreed ? { backgroundColor: 'rgb(38, 159, 168)', color: 'white' } : { backgroundColor: 'transparent', color: 'rgb(38, 159, 168)' }}>
          Ja, ich stimme der Datenverarbeitung meiner Daten zu.
        </button>
        <button className={styles.aggreementButton} onClick={() => setAggreed(false)}
          style={aggreed ? { backgroundColor: 'transparent', color: 'rgb(38, 159, 168)' } : { backgroundColor: 'rgb(38, 159, 168)', color: 'white' }}>
          Nein, ich stimme der Datenverarbeitung meiner Daten nicht zu.
        </button>
      </div>
    </div>
  );
}