import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

type Props = {
  children: JSX.Element[] | JSX.Element
}


export const ScrollToTop = ({ children }: Props) => {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (<>{children}</>);
}