import { Link } from 'react-router-dom';

import styles from './companyInfos.module.css';
import woman_writing_notes from './Assets/woman_writing_notes.jpg';
import people_smiling from './Assets/people_smiling.jpg';
import { InfoText } from '../../common/components/InfoText';



export const CompanyInfos = () => {
  return (
    <div>
      <div className={styles.landingImageContainer}>
        <div className={styles.landingImage}>
          <div className={styles.textContainer}>
            <div className={styles.textDescription}>
              AS ist der richtige Ansprechpartner, um Bewerber und Unternehmen zusammenzuführen.
              Wir tun alles um für Sie die richtigen Mitarbeiter zu finden.
            </div>
            <div className={styles.textDescription}>
              Flexibilität, Fairness, Zuverlässigkeit und Menschlichkeit stehen bei uns ganz oben.
            </div>
            <div className={styles.textDescription}>
              Ob Familienunternehmen, Mittelstand oder Konzerne, bei uns sind Sie genau richtig.
            </div>
          </div>
          <div className={styles.linkContainer}>
            <Link className={styles.tileHeader} to='/applyCompany'>Jetzt Personal finden{' >>'}</Link>
          </div>
        </div>
      </div>
      <InfoText header='Wie bereichert AS Ihr Unternehmen?'>
        Als Personalvermittlung verfügen wir über ein umfassendes Netzwerk an Bewerbern in verschiedenen Branchen und mit unterschiedlichen Erfahrungen.
        Wir haben umfangreiche Erfahrungen darin, die Bedürfnisse von Unternehmen zu verstehen und die perfekten Kandidaten zu finden, die Ihre offenen Stellen besetzen können.
        <p>
          Unser Ziel ist es, Ihnen den Stress und die Zeit zu sparen, die mit der Suche nach geeigneten Kandidaten verbunden sind. Wir kümmern uns um den gesamten Einstellungsprozess,
          von der Identifizierung von Kandidaten bis hin zur Durchführung von Hintergrundüberprüfungen und Interviews.
          Das gibt Ihnen die Freiheit, sich auf Ihr Kerngeschäft zu konzentrieren, während wir uns um Ihre Personalbedürfnisse kümmern.
        </p>
        <p>
          Wir glauben, dass wir Ihrem Unternehmen einen echten Mehrwert bieten können, indem wir Ihnen helfen, die besten Talente auf dem Markt zu finden und einzustellen.
        </p>
      </InfoText>
      <div className={styles.imgTextPairContainer}>
        <img className={styles.imgTextPairImg} src={woman_writing_notes} alt='' />
        <ul className={styles.imgTextPairList}>
          <h2>Ihre Vorteile auf einen Blick:</h2>
          <li>- Sie schonen ihre Ressourcen und können diese woanders einsetzen.</li>
          <li>- Genau die richtigen Kandidaten durch professionelle Vorauswahl.</li>
          <li>- Wir übernehmen für Sie entweder Teilprozesse, oder den kompletten Rekrutierungsprozess bis zur Vertragsunterzeichnung.</li>
          <li>- Sie profitieren von unserem Know-How.</li>
          <li>- Wir verfügen über modernste Rekrutierungskanäle</li>
          <li>- Sie erreichen auch Kandidaten, die nicht aktiv auf der Suche sind.</li>
        </ul>
      </div>
      <div className={styles.imgTextPairContainer}>
        <InfoText header='Was ist eine Personalvermittlung?'>
          Hat ein Unternehmen offene Vakanzen zu besetzen, bringt eine Personalvermittlung Bewerber und Unternehmen passgenau zusammen. Dabei kann die
          Personalvermittlung sowohl Teilprozesse, als auch den kompletten Rekrutierungsprozess übernehmen.
        </InfoText>
        <InfoText header='Was kostet eine Personalvermittlung?'>
          Das kann man pauschal nicht beantworten. Die Kosten richten sich nach der Qualifikation und dem Bruttojahresgehalt des angehenden Mitarbeiters.
          Je nach Dienstleister unterscheiden sich die Modelle erheblich.
        </InfoText>
      </div>
      <div className={styles.imgTextPairContainer}>
        <div className={styles.imgTextPairList}>
          <h2>Unser Handwerk:</h2>
          <p>
            Wir unterstützen und beraten Sie gerne bei der Suche nach passenden Kandidaten, die Ihr Unternehmen bereichern.
            Je nach Wunsch übernehmen wir Teilprozesse der Rekrutierung, oder auch den gesamten Prozess, von der Kandidatensuche und der professionellen
            Vorauswahl, bis hin zur Vertragsunterzeichnung bei Ihnen.
          </p>
          <p>
            Profitieren sie von
            <li>Langjähriger Expertise,</li>
            <li>Netzwerk & modernsten Rekrutierungskanälen,</li>
            <li>Genau die richtigen Kandidaten durch professionelle Vorauswahl.</li>
          </p>
        </div>
        <img className={styles.imgTextPairImg} src={people_smiling} alt='' />
      </div>
      <InfoText header='Was Sie als Unternehmer grundsätzlich beachten sollten'>
        Wie ist das Gebührenmodell der Personalvermittlung? Sind Vorauszahlungen fällig oder liegt erst eine Zahlung bei Vertragsunterzeichnung des neuen Mitarbeiters an?
        <p>
          Überprüfen Sie, ob ein seriöser Eindruck vorhanden ist. Ist der Webauftritt professionell, und sind Inhalte stimmig, oder wirkt der Auftritt unseriös? Lassen sie sich Grundsätzlich
          über den Prozess beraten und halten Sie alles vertraglich fest. Lassen Sie sich regelmäßig Updates geben, wo man im Prozess gerade steht. Eine seriöse Personalvermittlung macht das
          von sich aus und gibt Ihnen regelmäßiges Feedback.
        </p>
      </InfoText>
    </div>
  );
}