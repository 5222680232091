import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faEnvelope, faBars, faClose, faCookie, faDatabase, faBuilding, faUserPen, faSection, faPhone, faUser, faUserGroup, faBriefcase}
  from '@fortawesome/free-solid-svg-icons';

import styles from './mainMenu.module.css';
import { MenuButton } from '../../components/MenuButton';

export const MainMenu = () => {
  const [dropdownMenuOpen, setDropdownMenuOpen] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.mainMenuContainer}>
        <MenuButton alwaysVisible icon={faHome} label='Home' to='' />
        <MenuButton alwaysVisible icon={faUserPen} label='Bewerber' to='employeeInfos' />
        <MenuButton alwaysVisible icon={faBuilding} label='Unternehmen' to='companyInfos' />
        <button className={styles.openMenuButton} onClick={() => setDropdownMenuOpen(!dropdownMenuOpen)}>
          <FontAwesomeIcon icon={dropdownMenuOpen ? faClose : faBars} />
        </button>
      </div>
      {dropdownMenuOpen &&
        <div className={styles.dropdownMenuContainer} onClick={() => setDropdownMenuOpen(false)}>
          <MenuButton icon={faBriefcase} label='Bewerbungstipps' to='applicationTips' />
          <MenuButton icon={faUser} label='Jetzt Bewerben' to='applyEmployee' />
          <MenuButton icon={faUserGroup} label='Personal finden' to='applyCompany' />
          <MenuButton icon={faPhone} label='Rückruf anfordern' to='meeting' />
          <MenuButton icon={faEnvelope} label='Kontakt' to='contact' />
          <MenuButton icon={faSection} label='Impressum' to='credits' />
          <MenuButton icon={faCookie} label='Cookies' to='cookies' />
          <MenuButton icon={faDatabase} label='Datenschutz' to='dsgvo' />
        </div>
      }
    </div >
  );
}