import styles from './benefits.module.css';


export const Benefits = () => {
  return (
    <div className={styles.benefitsContainer}>
      <div className={styles.cardContainer}>
        <h1 className={styles.header}>Personalvermittlung ≠ Zeitarbeit</h1>
        <p className={styles.description}>
        Nein, die Personalvermittlung ist keine Zeitarbeit. Bei der Personalvermittlung werden Sie direkt in dem Unternehmen
        angestellt, für das wir Mitarbeiter suchen.
        </p>
      </div>
      <div className={styles.cardContainer}>
        <h1 className={styles.header}>Menschlich</h1>
        <p className={styles.description}>
        Der Mensch steht bei uns an erster Stelle. Bei AS wird sich für alle Partner Zeit genommen. 
        Weder das Unternehmen, noch der Bewerber werden im Fließbandverfahren abgearbeitet. Zeit, Kommunikation und Engament
        sind der Schlüssel zum Erfolg.
        </p>
      </div>
      <div className={styles.cardContainer}>
        <h1 className={styles.header}>Flexibel</h1>
        <p className={styles.description}>
        Sie wünschen sich Änderungen oder haben zusätzliche Wünsche? Wir helfen gerne weiter. 
        Wir reden nicht von Dienstleistungen und Flexibilität. Wir leben Dienstleistungen und Flexibilität.
        </p>
      </div>
      <div className={styles.cardContainer}>
        <h1 className={styles.header}>Passgenau</h1>
        <p className={styles.description}>
        Wir suchen und finden das Personal, das genau zu Ihren Ansprüchen passt. Dafür setzen wir alle unsere Ressourcen ein.
        </p>
      </div>
    </div>
  );
}